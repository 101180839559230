<template>
  <div class="node-record">
    <header-bar :showNotice="false" :showBack="true" :transparent="true" @setLanguage="setLanguageAfter"></header-bar>

    <div style="margin-top: -90px;">
      <title-name :title="$t('Record.tx')" label="Withdrawal history"></title-name>
    </div>

    <div class="list">
      <div class="list-name">
        <div>
          {{$t('Record.shijian')}}
        </div>
        <div class="justify-center">
          {{$t('Record.jine')}}
        </div>
        <div class="justify-end">
          {{$t('Record.hash')}}
        </div>
      </div>
      <!-- <van-list
        v-if="list.length > 0"
        v-model:loading="loading"
        :finished="finished"
        :finished-text="$t('NodeRecord.finished')"
        :immediate-check="false"
        offset="10"
        @load="onLoad"
      > -->
        <div class="list-val" v-for="item in list" :key="item.id">
          <div>{{ item.cdate }}</div>
          <div class="justify-center">{{ item.jine }}</div>
          <div class="justify-end text-right">
            {{ abbr(item.transactionHash, 4, 4) }}
            <q-icon name="content_copy" style="color: #000;margin-left: 4px;" @click="copy(item.transactionHash)"></q-icon>
          </div>
        </div>
      <!-- </van-list> -->
      <empty text="No records" v-if="list.length == 0" />
    </div>
  </div>
</template>

<script>
import HeaderBar from '@/components/HeaderBar'
import TitleName from '@/components/TitleName'
import Empty from '@/components/Empty'
import { ref } from 'vue'
import useClipboard from 'vue-clipboard3';

import { List, Tab, Tabs } from "vant";

export default {
  name: 'Bill',
  components: {
    HeaderBar,
    TitleName,
    Empty,
    [List.name]: List,
    [Tab.name]: Tab,
    [Tabs.name]: Tabs,
  },
  setup() {
    return {
      us: ref(null),
      pageIndex: ref(1),
      list: ref([]),
      loading: ref(false),
      finished: ref(false),
    }
  },
  created() {
    this.us = JSON.parse(this.$utils.getloc("us"))
    this.getdata()
  },
  methods: {
    onLoad() {
      this.loading = true
      this.pageIndex++
      this.getdata()
    },
    setLanguageAfter() {
      this.pageIndex = 1
      this.loading = false
      this.finished = false
      this.list = []
      this.getdata()
    },
    // 顶部组件的后退方法
    onBack() {
      this.$emit("close")
    },
    copy(val) {
			let that = this
			const { toClipboard } = useClipboard()
			try {
				toClipboard(val)
				that.$q.dialog({
					message: that.$t('Home.message1')
				})
			} catch (e) {
				console.log(e)
			}
		},
    abbr(val, start, end ) {
			if(val && val.length > 20) {
				let newVal = val.replace(
					val.substring(start, val.length - end),
					"****"
				)
				return newVal
			} else {
				return val
			}
		},
    getdata() {
      let _this = this
      _this.$request.post(
        "api/EthSendToken/List",
        {
          token: _this.$utils.getloc("token"),
          userid: _this.us.userid,
          uid: _this.us.id,
          lx: 0
        },
        (res) => {
          _this.loading = false
          if(res.data.code == 0) {
            _this.$q.dialog({
							message: res.data.msg,
						});
          }
          let data = res.data.data
          // if(data.length == 0) {
          //   _this.finished = true
          // } else {
          //   _this.finished = false
          // }
          _this.list = data
        }
      )
    }
  }
}
</script>

<style scoped>
.node-record {
  min-height: 100vh;
  background-image: linear-gradient(to right top, #2d8998, #519bab, #6fadbe, #8cc0d1, #a8d3e4, #b1dbec, #bbe2f3, #c4eafb, #bde8fa, #b7e5fa, #b0e3f9, #a9e1f8);
}

.back-wrap {
  padding: 0 12px;
}

.list {
  margin: 24px 12px 0;
  padding: 0 5px;
}

.list-name {
  display: flex;
  color: #a09999;
}

.list-name > div {
  flex: 1;
  display: flex;
  align-items: center;
}

.list-name img {
  margin-left: 8px;
  width: 14px;
}

.list-val {
  padding: 8px 0;
  display: flex;
  align-items: center;
  color: #fff;
}

.list-val > div {
  flex: 1;
  display: flex;
  align-items: center;
}
</style>